
import { defineComponent, reactive, computed, ref, onMounted } from "vue";
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import CrudActionsV2 from "../../components/c-module-master/CrudActionsV2.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { FormTypes } from "@/core/enums/form-types";
import moduleTable from "@/store/modules/cashiers/modules/table";
import { debounce } from "lodash";
import moduleForm from '@/store/modules/cashiers/modules/new-cashiers'
import ModalNewCashier from './modals/ModalNewCashier.vue'
//import FilterCatalog from "@/components/shared/filters/FilterCatalog.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , CrudActionsV2
        , ModalNewCashier
        //, FilterCatalog
    },
    setup(){
        const modeForm = ref(FormTypes.Create);
        const openModal = ref<HTMLButtonElement>(); 

        moduleTable.SEARCH_TABLE_DATA();
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo(a) cajero(a)",
        });
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "isActive", HeadLabel: "status", Type: "check", VisibleInGrid: false }
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });


        const handleChangePage = (page: number) => {
            //await goToPage(page);
            console.log(page);
        }
        const handleCrudAction = async(action: string | number = "N", id: string) => {
            //action element
            if(typeof action === "number"){
                modeForm.value = action;
            } else {
                modeForm.value = action == "N" ? FormTypes.Create
                                            : (action == "M" ? FormTypes.Update 
                                                                : action == "V" ? FormTypes.Read : FormTypes.Create);
            }
            if(modeForm.value != FormTypes.Create){
                moduleForm.SEARCH_DATA_FORM(id);
            }else {
                moduleForm.RESET_VALUES_FORM();
            }
            openModal.value?.click();
        }
        const changeStatus = (id: string, newStatus: boolean) => {
            moduleTable.CHANGE_STATUS({ id, newStatus })
        }

        const changeFilterStatus = (status: boolean) => {
            moduleTable.UPDATE_FILTERS({filterActive: status});
        }

        const searchItems = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});


        //store
        const totalRecords = computed(() => moduleTable.totalRecords);
        const records = computed(() => moduleTable.records);
        const selectedPage = computed(() => moduleTable.pagination.currentPage);
        const itemsPerPage = computed(() => moduleTable.pagination.pageSize);
        const filterStatus = computed(() => moduleTable.filters.filterActive);
        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "isActive");
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.CATALOGS.CASHIERS'), [translate('BREADCRUMB.CATALOGS.TITLE')]);
        });
        
        return {
            labels
            , totalRecords
            , selectedPage
            , itemsPerPage
            , renderRows
            , headers
            , openModal
            , modeForm
            , filterStatus
            , handleChangePage
            , handleCrudAction
            , searchItems
            , changeStatus
            , changeFilterStatus
        }
    }
})
