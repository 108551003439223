
import { defineComponent, toRefs, ref, computed, watch } from "vue";
import { Form, Field } from "vee-validate";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Select from "@/components/forms/Select.vue"
import { FormTypes } from "@/core/enums/form-types"
import * as Validations from "yup";
import moduleForm from '@/store/modules/cashiers/modules/new-cashiers'
import NewCashier from "@/models/cashiers/NewCashier";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import { filter } from "@/views/warehouse/common/commonUtilities";

export default defineComponent({
    components:{
        Form
        , InputText
        , SelectFilterRemote
        ,Select
        , Field
    }
    , props:{
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    },
    emits: ['close-content']
    , setup(props, { emit }){
        const selectedCashRegistrer = ref(false);
        const { formType } = toRefs(props);
        const form = ref();
        const messageService = new SwalMessageService();
        let cashregisters = ref([]);
        let supplierList = ref([]);
        const validationSchema =  Validations.object().shape({
            name: Validations.string().default('').required()
            , userId: Validations.string().required()
            , isAgent: Validations.bool().default(true).required()
        });
        
        const validationSelectedCashRegisterSchema =  Validations.object().shape({
            name: Validations.string().default('').required()
            , userId: Validations.string().required()
            , isAgent: Validations.bool().default(true).required()
            , cashRegisterId: Validations.string().required()
            , supplierId: Validations.string().required()
        });
        const validationSchemaDynamic = ref(validationSchema);

       
        
        const save = (form: NewCashier, {resetForm}) => {
             //call action
            if(formType.value == FormTypes.Create){
                moduleForm.SAVE_FORM(form).then( (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            } else {//edita si no es create
                moduleForm.UPDATE_FORM(form).then(  (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            }
        }
        const searchUsers = (filterName: string): void => { 
            //
            moduleForm.getComboUser(filterName);
        }

        const changeCheck = (val: boolean): void => {
            getCombCash();
            searchSupplier('');
            selectedCashRegistrer.value = val;
            if(selectedCashRegistrer.value){
                validationSchemaDynamic.value = validationSelectedCashRegisterSchema;
            }else{
                validationSchemaDynamic.value = validationSchema;
            }
        }

        const getCombCash = () => {
            // let parentParam = `?IsAgent=true`;
            // let url = `CashRegisters/GetAllCashRegisters${parentParam}`;
            // ApiService.query(url).then(res => {
            //     cashregisters.value = res.data;
            // }).catch(reject => {
            //     getMessageError(reject, messageService);
            // });

            ApiService.get(`CashRegisters`,'GetAllCashRegisters?IsAgent=true').then(res => {
                cashregisters.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }
        
        const searchSupplier = (filter: string): void => {
            ApiService.get(`Suppliers`,'combo'+ (filter != "" ? "?Criteria=" + filter : "") ).then(res => {
                supplierList.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const loading = computed(() => moduleForm.loading);
        const userOptions = computed(() => moduleForm.selects.userOptions);

        watch(() => moduleForm.getValuesForm, (currentValue: NewCashier) => {
            if(currentValue && currentValue.name != ''){
                searchUsers('');
                form.value.setValues({
                    ...(currentValue as NewCashier)
                });
            } else {
                form.value.resetForm();
            }
        })

        return {
            FormTypes
            , validationSchema
            , validationSelectedCashRegisterSchema
            , validationSchemaDynamic
            , form
            , loading
            , cashregisters
            , selectedCashRegistrer
            , changeCheck
            , getCombCash
            , messageService
            , save
            , searchUsers
            , userOptions

            ,searchSupplier
            ,supplierList
            
        }
    }
})
